<template>
	<div>
		<div class="pg_content_container">
			<header class="pg_header pg_content_1-1">
				<div class="flex">
					<div class="pg_header_heading">
						<h2>Bezetting per groepsles</h2>
					</div>

					<ButtonSelectGroup
						selected="Heatmap"
						:items="['Heatmap', 'Tabel']"
						:disabled="(isTable && !tableLoaded) || (!isTable && !heatmap.loaded)"
						@filterType="setOverviewType"
					/>
				</div>

				<div class="pg_header_description">
					<p v-if="isTable">
						Onderstaande tabel geeft de gemiddelde bezetting per les over de laatste 4 weken. Voor
						details per losse les kun je de heatmap gebruiken.
					</p>
					<p v-else>Onderstaande heatmap geeft per individuele les de bezetting weer.</p>
				</div>
			</header>
		</div>
		<div :class="{ pg_content_container: !heatmap }">
			<div class="pg_content_1-1 a_padding-left-0 a_padding-right-0">
				<VisitsStatistics
					v-if="isTable"
					ref="statistics"
					:limits="[10, 20, 30]"
					:loaded="tableLoaded"
					@getPlanningPerLes="getGroeplesData"
					@loadTable="loadTable"
				/>
				<VisitsHeatmap
					v-else
					:current-date="currentDate"
					:loaded="heatmap.loaded"
					:trainers="heatmap.trainers"
					:vestigingen="heatmap.vestigingen"
					:lessen="heatmap.lessen"
					@loadHeatmap="loadHeatmap"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { logger } from "@/logger";
import ButtonSelectGroup from "../../../elements/ButtonSelectGroup.vue";
import VisitsHeatmap from "./VisitsHeatmap";
import VisitsStatistics from "./VisitsStatistics";

export default {
	components: {
		VisitsStatistics,
		VisitsHeatmap,
		ButtonSelectGroup,
	},
	data() {
		return {
			lessen: null,
			tableLoaded: false,
			dateCache: {
				week: null,
				year: null,
			},
			heatmap: {
				loaded: false,
				trainers: null,
				vestigingen: null,
				groepslessen: null,
				lessen: null,
			},
			overviewType: "Heatmap",
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
		...mapState("vestiging", ["vestigingen"]),
		...mapState("groepsles", ["groepslessen"]),
		...mapState("statistieken", ["query"]),
		...mapGetters("groepsles", ["groepslesPlanningen"]),
		isTable() {
			return this.overviewType === "Tabel";
		},
		diffType() {
			return {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[this.query.periode];
		},
		currentDate() {
			return this.query && this.query.opening
				? this.query.opening
						.clone()
						.add(this.query.index, this.diffType)
						.startOf(this.diffType.slice(0, -1))
				: moment();
		},
	},
	watch: {
		query: {
			async handler() {
				if (
					this.currentDate.week() === this.dateCache.week &&
					this.currentDate.year() === this.dateCache.year
				) {
					return;
				}

				if (this.isTable) return;

				this.heatmap.loaded = false;

				this.weekLessen = await this.getLessenOfWeek({
					week: this.currentDate.week(),
					year: this.currentDate.year(),
				});

				this.dateCache = {
					week: this.currentDate.week(),
					year: this.currentDate.year(),
				};

				await this.loadHeatmap();
			},
			deep: true,
		},
		selectedBranch: {
			deep: true,
			async handler() {
				if (this.isTable) {
					this.tableLoaded = false;
				} else {
					this.heatmap.loaded = false;
				}

				this.weekLessen = await this.getLessenOfWeek({
					week: this.currentDate.week(),
					year: this.currentDate.year(),
				});

				if (this.isTable) {
					await this.loadTable();
				} else {
					await this.loadHeatmap();
				}
			},
		},
	},
	async mounted() {
		this.weekLessen = await this.getLessenOfWeek({
			week: this.currentDate.week(),
			year: this.currentDate.year(),
		});

		this.dateCache = {
			week: this.currentDate.week(),
			year: this.currentDate.year(),
		};

		if (this.tabel) {
			await this.loadTable();
		} else {
			await this.loadHeatmap();
		}
	},
	methods: {
		...mapMutations("groepsles", ["clearGroepslesPlanning"]),
		...mapActions("vestiging", ["getVestigingen"]),
		...mapMutations("groepsles", ["setGroepslesPlanning", "setGroepslesBezettingPerLes"]),
		...mapActions("lessen", ["getTable"]),
		...mapActions("groepsles", [
			"getGroepslessen",
			"getGroepslesPlanning",
			"getGroepslesBezetting",
			"getGroepslesBezettingPerLes",
		]),
		...mapActions("rooster", {
			getTrainers: "getTrainers",
			getRoosterVestigingen: "getVestigingen",
			getRoosterGroepslessen: "getGroepslessen",
			getLessenOfWeek: "getLessenOfWeek",
			getHeatMapLessenOfWeek: "getHeatMapLessenOfWeek",
			getLesById: "getLes",
		}),
		setOverviewType(type) {
			this.overviewType = type;
		},
		async loadTable() {
			this.clearGroepslesPlanning();
			await this.getData();

			this.lessen = await this.getTable("jaar");
		},
		async loadHeatmap() {
			if (!this.weekLessen) {
				return;
			}

			this.getRoosterVestigingen();

			[this.heatmap.trainers, this.heatmap.vestigingen, this.heatmap.lessen] = await Promise.all([
				this.getTrainers(),
				this.vestigingen,
				this.getHeatMapLessenOfWeek({
					week: this.currentDate.week(),
					year: this.currentDate.year(),
				}),
			]);

			for (let i = 0; i < this.heatmap.lessen.length; i++) {
				const heatmapLes = this.heatmap.lessen[i];

				let { bezettingsPercentage } = heatmapLes;
				bezettingsPercentage = heatmapLes.bezettingsPercentage.toString().includes("%")
					? heatmapLes.bezettingsPercentage
					: heatmapLes.bezettingsPercentage + "%";

				const les = this.weekLessen.find((les) => les.id === heatmapLes.id);
				const groepsles = this.groepslessen.find((groepsles) => groepsles.id === les.groepslesId);

				this.heatmap.lessen[i] = {
					...les,
					bezettingsPercentage,
					groepsles,
				};
			}

			this.heatmap.loaded = true;
		},
		async getGroeplesData(groepsles) {
			//for(const groepsles of this.groepslesPlanningen){
			const { id } = groepsles;

			const res = await this.getGroepslesBezettingPerLes(id);
			let { data } = res;

			// compare helper function
			function compare(x, y) {
				if (x === y) {
					return 0;
				}
				return x > y ? 1 : -1;
			}

			const days = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"];
			data = data.sort((a, b) => {
				const dayA = days.indexOf(a.dag);
				const dayB = days.indexOf(b.dag);
				if (dayA !== dayB) {
					return compare(dayA, dayB);
				}
				return compare(a.tijd, b.tijd);
			});

			data.forEach((item) => {
				const vestiging = this.vestigingen.find((vestiging) => vestiging.id === item.vestigingId);
				item.vestiging = vestiging.naam;

				// default to live
				item.lesType = "live";

				this.weekLessen.forEach((les) => {
					if (les.groepslesId === id && les.vestigingId === item.vestigingId) {
						const time = moment(les.datum).format("HH:mm");
						const day = moment(les.datum).weekday();
						const dag = days[day];

						if (item.dag === dag && item.tijd === time) {
							item.lesType = les.itemType;
						}
					}
				});
			});

			await this.setGroepslesBezettingPerLes({
				id,
				data,
			});

			//}

			this.$forceUpdate();

			//force the collapse to update the height
			await this.$nextTick();
			const { statistics } = this.$refs;
			const collapse = statistics.$refs[`collapse-${id}`][0];
			collapse.setHeight();
		},
		async getData() {
			if (!this.groepslessen.length) {
				try {
					await this.getGroepslessen();
				} catch (error) {
					logger.error(error);
				}
			}

			await this.setGroepslesBezetting();

			this.tableLoaded = true;
		},
		async setGroepslesBezetting() {
			const grouplessonOccupancies = await Promise.all(
				this.groepslessen.map(async (les) => {
					return {
						id: les.id,
						bezettingsPercentage: (await this.getGroepslesBezetting(les.id)).data,
					};
				}),
			);
			for (const les of grouplessonOccupancies) {
				this.setGroepslesPlanning(les);
			}
		},
		// async localGetGroepslesPlanningen() {
		//     const requests = this.groepslessen.map(({ id }) => {
		//         return this.getGroepslesPlanning(id)
		//     });

		//     try {
		//         await Promise.all(requests);
		//     } catch (error) {
		//         logger.error(error);
		//     }
		// }
	},
};
</script>

<style scoped lang="scss">
.pg_header_heading {
	h2 {
		margin: 0;
	}
}
.pg_header_select {
	align-items: center;
	display: flex;
	label {
		color: $brand-primary-lighter;
	}
	select {
		flex-grow: 0;
		margin: 0;
		margin-left: 0.6rem;
		width: auto;
	}
}

.flex {
	display: flex;
	justify-content: space-between;
}
</style>
