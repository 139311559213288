<template>
	<div>
		<!-- <period></period> -->
		<section class="pg_content a_margin-bottom-40">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h1 class="s_elem-has-icon-left a_fill_default">
								Groepslessen
								<icon :icon-id="'icon_groepsles'"></icon>
							</h1>
						</div>
						<div class="pg_header_description">
							<p>
								De belangrijkste indicatoren betreft de groepslessen van
								{{ selectedBranch.vestigingNaam }}
							</p>
						</div>
					</header>

					<LesFilter @filter="setFilter" @filterType="setFilterType" />

					<div class="pg_kpis a_margin-top-40 a_margin-bottom-20" data-s-amount="4">
						<statistics-badge-rect
							title="Plekken vrij"
							:soort="appendType('aantal-vrije-les-plekken')"
							:selected="selectedBadge == appendType('aantal-vrije-les-plekken')"
							@selected="selectedBadge = 'aantal-vrije-les-plekken'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Plekken bezet"
							:soort="appendType('aantal-les-deelnames')"
							:selected="selectedBadge == 'aantal-les-deelnames'"
							@selected="selectedBadge = appendType('aantal-les-deelnames')"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Bezettingsgraad"
							:soort="appendType('les-bezettingsgraad')"
							:selected="selectedBadge == 'les-bezettingsgraad'"
							:value-formatter="formatRatio"
							@selected="selectedBadge = appendType('les-bezettingsgraad')"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Unieke bezoekers"
							:soort="appendType('aantal-unieke-les-bezoeken')"
							:selected="selectedBadge == appendType('aantal-unieke-les-bezoeken')"
							@selected="selectedBadge = appendType('aantal-unieke-les-bezoeken')"
						></statistics-badge-rect>
					</div>
					<visits-chart :type="selectedBadge"></visits-chart>
					<hr />
				</div>
			</div>
		</section>
		<section class="pg_content a_margin-top-40">
			<VisitsStatisticsWrapper v-if="groepslessen.length" />
		</section>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LesFilter from "@/components/elements/LesFilter.vue";
import StatisticsBadgeRect from "@/components/elements/StatisticsBadgeRect.vue";
import { logger } from "@/logger";
import VisitsChart from "./partials/VisitsChart.vue";
import VisitsStatisticsWrapper from "./partials/VisitsStatisticsWrapper.vue";

export default {
	name: "Groepslessen",
	components: {
		StatisticsBadgeRect,
		VisitsChart,
		VisitsStatisticsWrapper,
		LesFilter,
	},
	data() {
		return {
			selectedBadge: "aantal-vrije-les-plekken",
			filterType: "all",
			lesFilter: (les) => {
				// Override this function to filter the groepslessen
				return !!les;
			},
		};
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		...mapState("branches", { selectedBranch: "selected" }),
		...mapState("statistieken", ["query"]),
		...mapState("groepsles", ["groepslessen"]),
		...mapGetters("rooster", ["getAmountGroepslessenOfRooster"]),
		mappedGroepslessen() {
			return this.groepslessen
				.slice()
				.sort((a, b) => {
					if (a.naam < b.naam) return -1;
					if (a.naam > b.naam) return 1;
					return 0;
				})
				.map((groepsles) => {
					groepsles.geplandeLessen = this.getAmountGroepslessenOfRooster(groepsles.id);
					return groepsles;
				});
		},
	},
	watch: {
		selectedBranch: {
			deep: true,
			async handler() {
				await this.getGroepslessen();
			},
		},
	},
	async created() {
		await this.getGroepslessen();
	},
	methods: {
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		...mapActions("groepsles", ["getGroepslessen"]),
		appendType(base) {
			if (this.filterType === "all") return base;

			let type = "";
			if (this.filterType === "live") {
				type = "live";
			} else if (this.filterType === "virtual") {
				type = "virtueel";
			}
			// else if (this.filterType === buiten) {
			//   type = 'buiten';
			// }

			const res = `${base}-${type}`;
			logger.log(res);
			return res;
		},
		setFilter(lesFilter) {
			this.lesFilter = lesFilter;
		},
		setFilterType(filterType) {
			this.filterType = filterType;
		},
		formatRatio(ratio) {
			const newRatio = ratio < 0 ? 0 : ratio;
			const rounded = Math.round((newRatio + Number.EPSILON) * 100) / 100;
			return `${rounded}%`;
		},
	},
};
</script>

<style lang="scss">
.vst_table-container.pg_table_lessons {
	&:before {
		left: 160px !important;
	}
}
</style>
