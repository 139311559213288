<template>
	<div class="pg_collapse" :class="{ s_active: open }">
		<div class="pg_collapse-bar" @click="toggle">
			<button class="pg_collapse-bar_button">
				<slot name="title" />
				<span class="pg_collapse_toggler" :class="{ s_active: open }">▶</span>
			</button>
			<slot name="data" />
		</div>
		<div ref="content" class="pg_collapse_content" :style="{ height: `${height}px` }">
			<slot name="content" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		update: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			height: 0,
			open: false,
		};
	},
	watch: {
		update(val) {
			if (val) {
				this.setHeight();
			}
		},
	},
	methods: {
		toggle() {
			this.open = !this.open;

			if (this.height !== 0) {
				this.height = 0;
				return;
			}

			this.setHeight();
		},
		setHeight() {
			const children = this.$refs.content.children;
			let height = 0;
			Array.from(children).forEach((child) => {
				height += child.offsetHeight;
			});

			this.height = height;
		},
	},
};
</script>
