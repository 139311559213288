<template>
	<button class="vst_sort-btn" :class="[...classes.button]" :style="styles.button">
		<span
			:class="direction === 'ascending' ? activeClasses : {}"
			:style="direction === 'ascending' ? activeStyle : {}"
			background-color="none"
		>
			▲
		</span>
		<span
			:class="direction === 'descending' ? activeClasses : {}"
			:style="direction === 'descending' ? activeStyle : {}"
			background-color="none"
		>
			▼
		</span>
	</button>
</template>

<script>
export default {
	name: "SortIcon",
	props: {
		classes: {
			type: [String, Array, Object],
			default: "",
		},
		styles: {
			type: [String, Object],
			default: "",
		},
		direction: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			defaultActiveClasses: ["s_active"],
			defaultActiveStyle: {},
			activeClasses: {},
			activeStyle: {},
		};
	},
	watch: {
		"classes.active": {
			immediate: true,
			handler(newActiveClasses) {
				const activateClasses = (activeClasses, currentClass) => ({
					...activeClasses,
					[currentClass]: true,
				});

				this.activeClasses = {
					...this.defaultActiveClasses.reduce(activateClasses, {}),
					...newActiveClasses.reduce(activateClasses, {}),
				};
			},
		},
		"styles.active": {
			immediate: true,
			handler(newActiveStyles) {
				this.activeStyle = {
					...this.defaultActiveStyle,
					...newActiveStyles,
				};
			},
		},
	},
};
</script>
<style lang="scss" scoped>
// Opacity & z-index fix for scroll-table sort buttons
.vst_table-scroll .vst_sort-btn {
	position: relative;
	z-index: 0;
}

span {
	opacity: 0.5;
	display: block;
	&.s_active {
		opacity: 1;
		background-color: none !important;
	}
}
</style>
