<template>
	<button :class="mergedClasses" @click="onClick">
		<slot></slot>
	</button>
</template>

<script>
export default {
	props: {
		variant: {
			type: String,
			default: "primary",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		classes: {
			type: String,
			default: "",
		},
	},
	computed: {
		mergedClasses() {
			return {
				[this.classes]: true,
				v_brand_primary: this.variant === "primary",
				v_ghost_brand_primary: this.variant !== "primary",
			};
		},
	},
	methods: {
		onClick() {
			if (!this.disabled) {
				this.$emit("click");
			}
		},
	},
};
</script>

<style lang="scss">
button {
	/* Add your button styles here */
	padding: 10px 20px;
	border-radius: 5px;
}

.primary-button {
	/* Add your primary button styles here */
	background-color: $primary-color !important;
	color: $white;
}

.secondary-button {
	border: 1px solid $primary-color;
	background-color: $white;
	color: $primary-color;
	/* Add your secondary button styles here */
}

.disabled-button {
	/* Add your disabled button styles here */
	/* You can customize the appearance or opacity for disabled state */
}
</style>
