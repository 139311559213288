<template>
	<div class="les-filter-container">
		<span class="les-filter-container_header">Les type</span>
		<div class="les-filter-container_group">
			<Button
				:variant="getVariant('all')"
				classes="a_no-margin button v_smallest v_has-icon-right v_icon-size-16"
				@click="setFilter('all')"
			>
				<span class="btn-info">Alle</span>
			</Button>

			<Button
				:variant="getVariant('live')"
				classes="a_no-margin button v_smallest v_has-icon-right v_icon-size-16"
				@click="setFilter('live')"
			>
				<span class="btn-info">Live</span>
				<icon :icon-id="'icon_live'" class="icon"></icon>
			</Button>

			<Button
				:variant="getVariant('virtual')"
				classes="a_no-margin button v_smallest v_has-icon-right v_icon-size-16"
				@click="setFilter('virtual')"
			>
				<span class="btn-info">Virtueel</span>
				<icon :icon-id="'icon_virtual'" class="icon"></icon>
			</Button>

			<Button
				:variant="getVariant('buiten')"
				classes="a_no-margin button v_smallest v_has-icon-right v_icon-size-16"
				@click="setFilter('buiten')"
			>
				<span class="btn-info">Buiten</span>
				<icon :icon-id="'icon_buiten'" class="icon"></icon>
			</Button>
		</div>
	</div>
</template>

<script>
import Button from "./Button.vue";
import Icon from "./Icon.vue";

export default {
	components: {
		Button,
		Icon,
	},
	data() {
		return {
			filter: "all",
		};
	},
	computed: {
		lessonFilter() {
			return (les) => {
				if (this.filter === "all") return true;
				return les.itemType === this.filter;
			};
		},
	},
	methods: {
		setFilter(filter) {
			this.filter = filter;
			this.$emit("filter", this.lessonFilter);
			this.$emit("filterType", filter);
		},
		getVariant(itemType) {
			return itemType === this.filter ? "primary" : "secondary";
		},
	},
};
</script>

<style lang="scss" scoped>
.les-filter-container {
	&_header {
		font-size: 14px;
		margin-bottom: 10px;
		display: block;
		color: $brand-primary-lighter;
	}
	&_group {
		flex: 1;
		display: flex;
		gap: 10px;
	}
}
</style>
