<template>
	<div>
		<div class="pg_pagination a_margin-bottom-20">
			<div class="pg_pagination_filters a_margin-right-20">
				<select v-model="limit" class="v_transparent">
					<option
						v-for="(pageLimit, index) in limits"
						:key="`stats-limit-select-option-${index}`"
						:value="pageLimit"
					>
						{{ pageLimit }} per pagina
					</option>
				</select>
			</div>
			<div class="pg_pagination_links">
				<button
					v-for="i in pages"
					:key="`pagination-${i}`"
					:class="{ s_active: i == page }"
					@click="paginate(i)"
				>
					{{ i }}
				</button>
			</div>
		</div>
		<div class="pg_visits-statistics">
			<div class="pg_visits-statistics_head">
				<span class="a_padding-20" @click="toggleDirection('group')">
					Groepsles
					<sort-icon
						:classes="{
							button: 'button v_transparent_body v_is-icon v_icon-size-16 a_no-margin a_no-padding',
							active: ['s_active'],
						}"
						:styles="{ button: { display: 'inline-block' }, active: '' }"
						:direction="groupDirection"
					/>
				</span>
				<span
					class="a_padding-20"
					:style="{ width: '220px' }"
					@click="toggleDirection('bezetting')"
				>
					Bezettingspercentage
					<sort-icon
						:classes="{
							button: 'button v_transparent_body v_is-icon v_icon-size-16 a_no-margin a_no-padding',
							active: ['s_active'],
						}"
						:styles="{ button: { display: 'inline-block' }, active: '' }"
						:direction="bezettingDirection"
					/>
				</span>
			</div>
			<div>
				<template v-if="loaded">
					<Collapse
						v-for="groepsles in limitedData"
						:ref="`collapse-${groepsles.id}`"
						:key="`groeples-stats-collapse-${groepsles.id}`"
						@click.native="
							!groepslesPlanningPerLes[groepsles.id] ? $emit('getPlanningPerLes', groepsles) : null
						"
					>
						<template #title>
							<span class="a_padding-20">{{ groepsles.naam }}</span>
						</template>
						<template #data>
							<span class="a_padding-20" :style="{ width: '220px' }">
								{{ groepsles.planning.bezettingsPercentage }}
							</span>
						</template>
						<template #content>
							<div class="pg_groeples_collapse_content">
								<loader
									v-if="!groepslesPlanningPerLes[groepsles.id]"
									class="pg_groeples_collapse_content_loader"
									:loading="true"
								/>
								<vue-scroll-table
									v-else
									:ref="`pg_groepsles-table-${groepsles.id}`"
									class="pg_table_group-lessons"
									:headers="headers"
									:data="groepslesPlanningPerLes[groepsles.id]"
									:has-scroll="false"
									:classes="tableStylingClasses"
								/>
								<!-- <span v-else class="pg_groeples_collapse_content">Er zijn geen lessen beschikbaar.</span> -->
							</div>
						</template>
					</Collapse>
				</template>
				<div v-else class="pg_visits-statistic_loader">
					<Loader :loading="true" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueScrollTable from "vue-scroll-table";
import { mapActions, mapGetters, mapState } from "vuex";
import Collapse from "@/components/elements/Collapse.vue";
import Loader from "@/components/elements/Loader.vue";
import SortIcon from "@/components/elements/SortIcon.vue";

export default {
	components: {
		VueScrollTable,
		Collapse,
		SortIcon,
		Loader,
	},
	props: {
		limits: {
			type: Array,
			default: () => [25, 50, 100],
		},
		loaded: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			limit: null,
			page: 1,
			toggle: "group",
			groupDirection: "ascending",
			bezettingDirection: "ascending",
			update: {},
			lesFilter: () => true,
			headers: [
				{
					text: "Dag",
					width: 160,
					sortable: true,
				},
				{
					text: "Lestype",
					sortable: true,
				},
				{
					text: "Tijdstip",
					sortable: true,
				},
				{
					text: "Locatie",
					sortable: true,
				},
				{
					text: "Coach",
					sortable: true,
				},
				{
					text: "Bezettingspercentage",
					width: 220,
					sortable: true,
				},
			],
			tableStylingClasses: {
				tableContainer: ["pg_table", "pg_table_vst", "pg_table_lessons"],
				pagination: {
					container: ["pg_pagination"],
				},
				scroll: {
					table: ["pg_table"],
				},
				sticky: {
					container: ["pg_table-overlay_vst"],
					table: ["pg_table-overlay"],
				},
				sortButtons: {
					button: [
						"button",
						"v_transparent_body",
						"v_is-icon",
						"v_icon-size-16",
						"a_no-margin",
						"a_no-padding",
					],
				},
			},
		};
	},
	computed: {
		...mapState("groepsles", ["groepslesPlanningPerLes"]),
		...mapGetters("groepsles", ["groepslesPlanningen"]),
		filteredGroepslessen() {
			return this.groepslesPlanningen.filter(this.lesFilter).filter((les) => {
				const percentage = les.planning.bezettingsPercentage;
				if (percentage === "-" || percentage === "0%") {
					return false;
				}

				return true;
			});
		},
		sortedGroepslessen() {
			return [...this.filteredGroepslessen].sort((a, b) => {
				const usageA =
					this.toggle === "group"
						? a.naam
						: parseInt(a.planning.bezettingsPercentage.replace("%", ""));
				const usageB =
					this.toggle === "group"
						? b.naam
						: parseInt(b.planning.bezettingsPercentage.replace("%", ""));
				const direction = this[`${this.toggle}Direction`];

				if (usageA < usageB) return direction === "ascending" ? -1 : 1;
				if (usageA > usageB) return direction === "ascending" ? 1 : -1;
				return 0;
			});
		},
		limitedData() {
			const limitedData = this.sortedGroepslessen.filter((row, i) => {
				if (i >= (this.page - 1) * this.limit && i < this.page * this.limit) return true;
				return false;
			});

			if (!this.limit) return this.sortedGroepslessen || [];
			return limitedData;
		},
		pages() {
			if (!this.sortedGroepslessen.length || !this.limit) return 0;
			const pages = Math.ceil(this.sortedGroepslessen.length / this.limit);
			return pages;
		},
	},
	watch: {
		limit() {
			this.page = 1;
		},
	},
	async mounted() {
		if (this.limits.length) {
			this.limit = this.limits[0];
		}

		if (!this.loaded) {
			this.$emit("loadTable");
		}
	},
	methods: {
		...mapActions("groepsles", ["getGroepslesBezettingPerLes"]),
		toggleDirection(type) {
			this.toggle = type;
			if (this[`${type}Direction`] === "ascending") {
				this[`${type}Direction`] = "descending";
				return;
			}

			this[`${type}Direction`] = "ascending";
		},
		paginate(num) {
			this.page = num;
		},
	},
};
</script>

<style lang="scss">
.pg_visits-statistics {
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid #ddd;
	&_head {
		display: flex;
		justify-content: space-between;
		font-weight: 700;
		background: rgba($brand-primary, 0.05);
		> span {
			position: relative;
			cursor: pointer;
			button span {
				font-size: 0.6rem;
			}
		}
		> span:nth-child(1) {
			flex-grow: 1;
			border-right: 1px solid #fff;
		}
	}
}

.pg_groeples_collapse_content {
	display: block;
	position: relative;
	> span {
		padding: 0.5rem 1rem;
	}
	&_loader {
		padding: 0.5rem 1rem;
		position: static !important;
	}
}

.pg_visits-statistic_loader {
	position: relative;
	padding: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.pg_loader {
		position: static;
		width: auto;
	}
}
</style>
