import Vue from "vue";
import moment from "moment";

// 17:40
Vue.filter("tijdstip", (value) => moment(value).format("H:mm"));

// 31 mei
Vue.filter("dagMaand", (value) => moment(value).format("D MMMM"));

Vue.filter("dagMaandKort", (value) => moment(value).format("D/M"));

// vrijdag
Vue.filter("weekDag", (value) => moment(value).format("dddd"));

// vr
Vue.filter("weekDagKort", (value) => {
	const day = moment(value).format("ddd");
	if (day) {
		return day.replace(".", "");
	}
	return "";
});

Vue.filter("thisWeek", (value) => moment(value).week() === moment().week());

Vue.filter("count", (value) => value.length);

Vue.filter("capitalize", (value) => {
	if (typeof value === "string") {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}
	return value;
});
