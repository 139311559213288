<template>
	<div>
		<div v-if="!loaded" class="pg_heatmap_loader_wrapper">
			<Loader :loading="true" />
		</div>
		<template v-else>
			<LesFilter @filter="setFilter" />

			<Schedule
				:disable-lesson-click="true"
				:show-filter-bar="true"
				:lessen="scheduleData"
				:date="currentDate"
				:print-schema="false"
				:vestigingen="vestigingenAlphabetically"
				:groepslessen="groepslessenAlphabetically"
				:trainers="trainersAlphabetically"
				:initial-vestiging="selectedBranch.id"
				:disable-vestiging-switcher="true"
				:disable-change-week="true"
				:disable-done="false"
				class="pg_schedule"
			>
				<template #schedule_item="{ les }">
					<div class="pg_schedule_link_wrapper">
						<div class="pg_schedule_link">
							<div class="pg_schedule_link_content">
								<span v-if="les.groepsles" class="pg_schedule_link_title">{{
									les.groepsles.naam
								}}</span>
								<span class="pg_schedule_link_time">{{
									`${les.bezettingsPercentage || "0%"}`
								}}</span>
								<div
									class="pg_schedule_link_bg"
									:style="{
										transform: `translateX(-${les.bezettingsPercentage})`,
									}"
								/>
							</div>
						</div>
						<div class="pg_schedule_link_popup">
							<span v-if="les.groepsles" class="pg_schedule_link_popup_coach"
								><b>Les:</b> {{ les.groepsles.naam }}</span
							>
							<span v-if="les.trainer" class="pg_schedule_link_popup_coach"
								><b>Coach:</b> {{ les.trainer.naam }}</span
							>
							<span class="pg_schedule_link_popup_coach"
								><b>Bezetting:</b> {{ les.bezet }} / {{ les.plekken }}
							</span>
						</div>
					</div>
				</template>
				<template #legend>
					<div class="pg_schedule_legend_item v_reserved-lesson">
						<div class="pg_schedule_legend_item_circle"></div>
						<div class="pg_schedule_legend_item_label">Bezet</div>
					</div>
					<div class="pg_schedule_legend_item v_cancelled-lesson">
						<div class="pg_schedule_legend_item_circle"></div>
						<div class="pg_schedule_legend_item_label">Leeg</div>
					</div>
				</template>
			</Schedule>
		</template>
	</div>
</template>

<script>
import moment from "moment";
import Schedule from "pfg-rooster/src/components/Schedule.vue";
import { mapState } from "vuex";
import LesFilter from "@/components/elements/LesFilter.vue";
import Loader from "@/components/elements/Loader.vue";

export default {
	components: {
		Schedule,
		Loader,
		LesFilter,
	},
	props: {
		currentDate: {
			type: Object,
			default: null,
		},
		loaded: {
			type: Boolean,
			default: false,
		},
		trainers: {
			type: Array,
			default: null,
		},
		vestigingen: {
			type: Array,
			default: null,
		},
		lessen: {
			type: Array,
			default: null,
		},
		lesFilter: {
			type: Function,
			default: () => true,
		},
	},
	computed: {
		...mapState("branches", { selectedBranch: "selected" }),
		...mapState("groepsles", ["groepslesPlanning"]),
		scheduleData() {
			return this.lessen.filter(this.lesFilter).map((les) => {
				this.$set(les, "trainer", this.getTrainerById(les.trainerId));
				this.$set(les, "zaal", this.getZaalById(les.zaalId));
				this.$set(les, "vestiging", this.getVestigingById(les.vestigingId));
				this.$set(les, "endDate", moment(les.datum).add(les.duur, "minute"));
				this.$set(les, JSON.stringify(les));
				return les;
			});
		},
		groepslessenAlphabetically() {
			if (!this.lessen.length) {
				return [];
			}
			return [...this.lessen].sort((a, b) => {
				if (a.naam < b.naam) return -1;
				if (a.naam > b.naam) return 1;
				return 0;
			});
		},
		vestigingenAlphabetically() {
			if (!this.vestigingen.length) {
				return [];
			}
			return [...this.vestigingen].sort((a, b) => {
				if (a.naam < b.naam) return -1;
				if (a.naam > b.naam) return 1;
				return 0;
			});
		},
		trainersAlphabetically() {
			if (!this.trainers.length) {
				return [];
			}
			return [...this.trainers].sort((a, b) => {
				if (a.naam < b.naam) return -1;
				if (a.naam > b.naam) return 1;
				return 0;
			});
		},
	},
	async mounted() {
		if (!this.loaded) {
			this.$emit("loadHeatmap");
		}
	},
	methods: {
		getTrainerById(id) {
			return this.trainers.find((sTrainer) => sTrainer.id === id);
		},
		getZaalById(id) {
			return this.vestigingen
				.reduce((zalen, currentVestiging) => {
					return [...zalen, ...currentVestiging.zalen];
				}, [])
				.find((zaal) => zaal.id === id);
		},
		getVestigingById(id) {
			return this.vestigingen.find((sVestiging) => sVestiging.id === id);
		},
		setFilter(filter) {
			this.lesFilter = filter;
		},
	},
};
</script>

<style lang="scss">
$topOffset: 6;

.pg_schedule_days {
	margin-top: #{$topOffset}rem;
}
.pg_schedule .table-scroll {
	padding-top: #{$topOffset}rem;
}
.pg_schedule_timeline_current {
	top: #{math.div($topOffset, 1.5)}rem;
	height: calc(100% - #{math.div($topOffset, 1.5)}rem);
}
.pg_schedule_timeline_line {
	top: #{math.div($topOffset, 1.5)}rem;
	height: calc(100% - #{math.div($topOffset, 1.5)}rem);
}

.pg_schedule_timeline_no-lessons {
	top: #{$topOffset}rem;
	height: calc(100% - #{$topOffset}rem);
}

.pg_schedule_table:after,
.pg_schedule_table:before {
	top: #{$topOffset}rem;
	height: calc(100% - #{$topOffset}rem);
}

// disable les type filter for now
.pg_schedule_filter-bar_column:nth-child(2) {
	display: none;
}
</style>

<style scoped lang="scss">
.pg_heatmap_loader_wrapper {
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.pg_loader {
		position: static;
		width: auto;
	}
}

.pg_schedule_link_wrapper {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	&:hover {
		.pg_schedule_link_popup {
			pointer-events: all;
			opacity: 1;
			transform: translateY(-100%) translateY(-0.8rem) translateX(-50%);
		}
	}
}

.pg_schedule_link {
	position: relative;
	transition: opacity 0.4s;
	border: none;
	background: $brand-green;
	padding: 0;
	&_content {
		padding: 0 6px;
		min-height: 100%;
		overflow: hidden;
		display: inline-flex;
		align-items: center;
		min-width: 0;
		z-index: 0;
		position: relative;
		width: 100%;
		justify-content: space-between;
	}
	&_bg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		right: 0;
		background-image: linear-gradient(90deg, $brand-red, $brand-yellow, $brand-green);
		z-index: 0;
		width: 10000%;
	}
	&_title {
		position: static;
		z-index: 1;
		overflow: hidden;
		flex-grow: 1;
		width: auto;
		text-overflow: ellipsis;
	}
	&_time {
		display: block !important;
		color: #fff;
		z-index: 1;
		flex-shrink: 0;
	}
	&_popup {
		pointer-events: none;
		opacity: 0;
		transform: translateY(-100%) translateY(0) translateX(-50%);
		transition:
			opacity 0.4s,
			transform 0.4s;
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 99999;
		background: $brand-primary;
		white-space: nowrap;
		padding: 0.6rem 1rem;
		&:after {
			content: "▼";
			position: absolute;
			bottom: 0;
			z-index: -1;
			font-size: 1.4rem;
			color: $brand-primary;
			transform: translateY(1.2rem) translateX(-50%);
			left: 50%;
		}
		span {
			display: block;
			font-size: 0.8rem;
			color: #fff;
		}
	}
}
</style>
