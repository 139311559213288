<template>
	<div class="les-filter-container">
		<span v-if="title" class="les-filter-container_header">{{ title }}</span>
		<div class="les-filter-container_group">
			<Button
				v-for="item in items"
				:key="item"
				:variant="getVariant(item)"
				:disabled="disabled"
				classes="a_no-margin button v_smallest v_has-icon-right v_icon-size-16"
				@click="setFilter(item)"
			>
				<span class="btn-info">{{ item }}</span>
			</Button>
		</div>
	</div>
</template>

<script>
import Button from "./Button.vue";

export default {
	components: {
		Button,
	},
	props: {
		title: {
			type: String,
			required: false,
			default: "",
		},
		items: {
			type: Array,
			required: true,
		},
		selected: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			filter: "all",
		};
	},
	computed: {
		currentFilter() {
			return (item) => {
				if (this.filter === "all") return true;
				return item.itemType === this.filter;
			};
		},
	},

	watch: {
		selected: {
			immediate: true,
			handler() {
				this.filter = this.selected;
			},
		},
	},

	methods: {
		setFilter(filter) {
			this.filter = filter;
			this.$emit("filter", this.currentFilter);
			this.$emit("filterType", filter);
		},
		getVariant(itemType) {
			return itemType === this.filter ? "primary" : "secondary";
		},
	},
};
</script>

<style lang="scss" scoped>
.les-filter-container {
	&_header {
		font-size: 14px;
		margin-bottom: 10px;
		display: block;
		color: $brand-primary-lighter;
	}
	&_group {
		flex: 1;
		display: flex;
		gap: 10px;
	}
}
</style>
